import {Flag} from 'platform/components';
import {Text} from 'platform/foundation';

import {RequiredTestIdProps} from 'shared';

export interface FeatureItemProps extends RequiredTestIdProps {
  title: string;
  isHighlighted?: boolean;
}
export function FeatureItem(props: FeatureItemProps) {
  if (props.isHighlighted) {
    return (
      <Flag
        label={props.title}
        isSubtle
        isBoldText
        colorScheme="purple"
        data-testid={props['data-testid']}
      />
    );
  }
  return (
    <Text alternative size="small" data-testid={props['data-testid']} color="secondary">
      {props.title}
    </Text>
  );
}
