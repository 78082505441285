import {Box, HStack, Text, VStack} from 'platform/foundation';

import {languageDialogItems} from '../../consts/languageDialogItems';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';
import {Separator} from '../Separator/Separator';
import {LanguageComponent} from './components/LanguageComponent';

export function Footer() {
  const {logo: applicationLogo, companyName} = getWorkspaceCustomization();
  const currentDate = new Date();
  return (
    <footer data-testid="footer">
      <Box paddingTop={4} width="100%" backgroundColor="fill.white">
        <Separator />
        <HStack justify="center">
          <Box
            data-testid="footer"
            width="100%"
            maxWidth={400}
            paddingVertical={[6, 10, 10, 14]}
            paddingHorizontal={[3, 8, 8, 20]}
          >
            <VStack spacing={[6, 8, 8, 8]}>
              <Box>
                <HStack spacing={4} align="center" justify="space-between">
                  {applicationLogo}
                  <Box>
                    <LanguageComponent languages={languageDialogItems} />
                  </Box>
                </HStack>
              </Box>
              <Box maxWidth={140}>
                <Text size="xSmall" color="tertiary">
                  Copyright © {currentDate.getFullYear()} by {companyName}. All Rights Reserved. No
                  part of this offer may be reproduced or used in any manner without written
                  permission from {companyName}.
                </Text>
              </Box>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </footer>
  );
}
