import {Box, Heading, VStack} from 'platform/foundation';

import {ReactElement} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

export interface CardContainerProps extends TestIdProps {
  heading: string;
  children?: ReactElement | ReactElement[];
}

export function CardContainerNew(props: CardContainerProps) {
  return (
    <Box data-testid={suffixTestId('cardContainer', props)}>
      <VStack spacing={8}>
        <Heading size={2}>{props.heading}</Heading>
        <VStack>{props.children}</VStack>
      </VStack>
    </Box>
  );
}
