import {motion} from 'framer-motion';
import {Spinner, HStack, Image} from 'platform/foundation';
import {css} from 'styled-components';

export function Loading() {
  return (
    <motion.section
      initial={{opacity: 0.1, scale: 0.95}}
      animate={{opacity: 1, scale: 1}}
      transition={{duration: 0.5}}
      css={css`
        min-width: 100vw;
        min-height: 100vh;
        min-height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${({theme}) => theme.getSize(3)};
      `}
    >
      <HStack spacing={4} align="center" justify="center">
        <Image src="./assets/images/logo.svg" alt="Online Offer" height={12} width="auto" />
        <Spinner size="large" />
      </HStack>
    </motion.section>
  );
}
