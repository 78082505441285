import {Show, Space, ThemeIconKey} from 'platform/foundation';

import {ReactElement} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Separator} from '../../components/Separator/Separator';
import {SectionId, SectionsNew} from '../../consts/SectionsNew';
import {CardContainerNew} from '../CardContainerNew/CardContainerNew';

export interface SectionProps extends TestIdProps {
  id: SectionId;
  heading: string;
  icon?: ThemeIconKey;
  children: ReactElement | ReactElement[];
  isWithBreakLine?: boolean;
}

export function SectionNew(props: SectionProps) {
  const section = SectionsNew.find((section) => section.id === props.id);
  const isWithBreakLine = props.isWithBreakLine ?? false;
  return (
    <>
      <section id={props.id} data-testid={suffixTestId('section', props)}>
        <CardContainerNew heading={section?.menuTitle ?? ''}>{props.children}</CardContainerNew>
      </section>
      <Space vertical={8} />
      <Show when={isWithBreakLine}>
        <Separator />
        <Space vertical={8} />
      </Show>
    </>
  );
}
