import {Heading, HStack, Icon, Text, ThemeIconKey, useDevice, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

type TechnicalParametersItemProps = {
  icon: ThemeIconKey;
  title: string;
  value: string;
};

export const TechnicalParametersItem = (props: TechnicalParametersItemProps) => {
  const device = useDevice();
  const iconSize = match(device)
    .with('mobile', always(5 as const))
    .with('tablet', always(7 as const))
    .with('notebook', always(7 as const))
    .otherwise(always(8 as const));
  return (
    <HStack align="center" spacing={4}>
      <Icon size={iconSize} value={props.icon} />
      <VStack spacing={1}>
        <Text color="secondary" size="xSmall">
          {props.title}
        </Text>
        <Heading size={4}>{props.value}</Heading>
      </VStack>
    </HStack>
  );
};
