import {LocaleContextValue} from 'platform/locale';

import {Nullish} from 'shared';

import {AlphaCatalogue, VehicleAudit} from '../api/digitalCertificateApi';
import {getAlphaCatalogueLabel} from '../utils/getAlphaCatalogueLabel';

export interface DetailsDataBundle {
  vin: string | Nullish;
  firstRegistration: string | Nullish;
  countryOfOrigin: string | Nullish;
  typeOfColour: string | Nullish;
  nameOfColour: string | Nullish;
  exteriorColour: {
    name: string | Nullish;
    value: string | Nullish;
  };
  interiorColour: {
    name: string | Nullish;
    value: string | Nullish;
  };
  interiorMaterial: string | Nullish;
  numberOfSeats: number | Nullish;
  numberOfDoors: string | Nullish;
  numberOfKeys: number | Nullish;
  numberOfOwners: number | Nullish;
  hybridType: string | Nullish;
  secondaryFuel: string | Nullish;
  emissionClass: string | Nullish;
  fuelUsageCombined: string | Nullish;
  electricalUsageCombined: string | Nullish;
  enginePower: number | Nullish;
  electricalEnginePower: number | Nullish;
  engineDisplacement: number | Nullish;
  numberOfPistons: number | Nullish;
  numberOfGears: number | Nullish;
  sellerNote: string | Nullish;
}

export const useGetDataForDetails = (
  vehicleAudit: VehicleAudit | Nullish,
  locale: LocaleContextValue,
  alphaCatalogue: AlphaCatalogue | Nullish
): DetailsDataBundle | null => {
  if (!vehicleAudit?.vehicle || !alphaCatalogue) {
    return null;
  }
  const countryOfOrigin = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'country',
    key: vehicleAudit.vehicle.originCountry,
    alphaCatalogue,
  });
  const typeOfColour = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'color_type',
    key: vehicleAudit?.vehicle.exteriorColorSpecification,
    alphaCatalogue,
  });
  const nameOfColour = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'body_color',
    key: vehicleAudit.vehicle.exteriorColorFactoryName,
    alphaCatalogue,
  });
  const exteriorColourName = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'body_color',
    key: vehicleAudit.vehicle.exteriorColor?.value,
    alphaCatalogue,
  });
  const interiorColourName = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'interior_color',
    key: vehicleAudit.vehicle.interiorColor?.value,
    alphaCatalogue,
  });
  const interiorMaterial = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'interior_material',
    key: vehicleAudit.vehicle.interiorMaterial,
    alphaCatalogue,
  });
  const numberOfDoors = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'door_count',
    key: vehicleAudit.vehicle.doorCountCategory,
    alphaCatalogue,
  });
  const hybridType = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'hybrid_type',
    key: vehicleAudit.vehicle.hybridType,
    alphaCatalogue,
  });
  const secondaryFuel = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'fuel_type',
    key: vehicleAudit.vehicle.fuelType,
    alphaCatalogue,
  });
  const emissionClass = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'emission_class',
    key: vehicleAudit.vehicle.emissionClass,
    alphaCatalogue,
  });
  const fuelUsageCombinedRatio = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'fuel_unit',
    key: 'FUEL_UNIT_L100KM',
    alphaCatalogue,
  });
  const fuelUsageCombined = vehicleAudit.vehicle.fuelConsumptionCombined
    ? `${vehicleAudit.vehicle.fuelConsumptionCombined} ${fuelUsageCombinedRatio}`
    : null;
  const electricalUsageCombinedRatio = getAlphaCatalogueLabel({
    vehicleType: vehicleAudit.vehicle.type,
    propertyName: 'fuel_unit',
    key: 'FUEL_UNIT_KWH100KM',
    alphaCatalogue,
  });
  const electricalUsageCombined = vehicleAudit.vehicle.powerConsumptionCombined
    ? `${vehicleAudit.vehicle.powerConsumptionCombined} ${electricalUsageCombinedRatio}`
    : null;
  return {
    vin: vehicleAudit.vehicle.vin,
    firstRegistration: vehicleAudit.vehicle.firstRegistrationOn,
    countryOfOrigin,
    typeOfColour,
    nameOfColour,
    // TODO: we can't assess code to value since the code is only for alpha catalogue
    exteriorColour: {name: exteriorColourName, value: exteriorColourName},
    // TODO: we can't assess code to value since the code is only for alpha catalogue
    interiorColour: {name: interiorColourName, value: interiorColourName},
    interiorMaterial,
    numberOfSeats: vehicleAudit.vehicle.seatCount,
    numberOfDoors,
    numberOfKeys: vehicleAudit.vehicle.primaryKeyCount,
    numberOfOwners: vehicleAudit.vehicle.ownerCount,
    hybridType,
    secondaryFuel,
    emissionClass,
    fuelUsageCombined,
    electricalUsageCombined,
    enginePower: vehicleAudit.vehicle.power,
    // TODO: no property for electrical engine power?
    electricalEnginePower: vehicleAudit.vehicle.power,
    engineDisplacement: vehicleAudit.vehicle.engineVolume,
    numberOfPistons: vehicleAudit.vehicle.cylinderCount,
    numberOfGears: vehicleAudit.vehicle.gearCount,
    // TODO: not sure if serviceContractNote is the seller note
    sellerNote: vehicleAudit.vehicle.serviceContractNote,
  };
};
