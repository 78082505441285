import {
  Box,
  Heading,
  HStack,
  Icon,
  Text,
  ThemeIconKey,
  useDevice,
  VStack,
} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, times} from 'ramda';

import {isDefined, Nullish} from 'shared';

import {i18n} from '../../i18n/i18n';

type CertificateCardRatingProps = {
  icon: ThemeIconKey;
  title: string;
  rating?: number | Nullish;
};

const mapRatingToStar: Record<number, number> = {
  1: 5,
  2: 4,
  3: 3,
  4: 2,
  5: 1,
};
export const CertificateCardRating = (props: CertificateCardRatingProps) => {
  const device = useDevice();
  const iconSize = match(device)
    .with('mobile', always(6 as const))
    .with('tablet', always(7 as const))
    .with('notebook', always(7 as const))
    .otherwise(always(10 as const));

  const starSize = match(device)
    .with('mobile', always(5 as const))
    .with('tablet', always(5 as const))
    .with('notebook', always(4 as const))
    .otherwise(always(5 as const));

  const rating = props.rating;

  const stars = isDefined(rating) ? (
    times(
      (index) => (
        <Icon
          key={index}
          value="iconStar"
          color={index < mapRatingToStar[rating] ? 'severity.warningBase' : 'severity.neutralBase'}
          size={starSize}
        />
      ),
      5
    )
  ) : (
    <Text color="tertiary">{i18n.t('unrated')}</Text>
  );

  return (
    <Box
      height="100%"
      borderRadius="small"
      paddingHorizontal={[4, 5, 5, 8]}
      paddingVertical={[3, 5, 5, 8]}
      backgroundColor="palettes.white.10.100"
    >
      <HStack spacing={[4, 2, 2, 4]} align="center">
        <Box>
          <Icon size={iconSize} value={props.icon} />
        </Box>
        <VStack spacing={[3, 3, 2, 3]}>
          <Heading size={4}>{props.title}</Heading>
          <HStack spacing={1}>{stars}</HStack>
        </VStack>
      </HStack>
    </Box>
  );
};
