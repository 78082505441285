import {LocaleContextValue} from 'platform/locale';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {SectionIdsType} from '../consts/Sections';
import {getFeatures} from '../utils/getFeatures';

export type FeaturesDataBundle = {
  categoriesWithFeatures: ReturnType<typeof getFeatures>;
  sectionConfig: {
    name: SectionIdsType;
    isHidden: boolean;
  };
};

export const useGetDataForFeatures = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): FeaturesDataBundle => ({
  categoriesWithFeatures: getFeatures({vehicleAudit, language: locale.language}),
  sectionConfig: {
    isHidden: false,
    name: 'EQUIPMENT',
  },
});
