import {componentsTheme} from 'platform/components';

import {themeIcons} from '../assets/icons/themeIcons';
import {onlineOfferTheme} from './theme';

const accent = {
  darkest: '#150104',
  darker: '#0A397B',
  dark: '#1359BB',
  base: '#1C69D4',
  light: '#79B1FF',
  lighter: '#C4DDFF',
  lightest: '#D9E9FF',
} as const;

const text = {
  primary: accent.darkest,
  secondary: '#42526E',
  tertiary: '#7A869A',
  inverted: '#FFFFFF',
  accent: accent.base,
  link: '#0047C1',
} as const;

const fill = {
  greyLight: '#FAFBFC',
  white: '#FFFFFF',
  overlayStrong: '#000C20F2',
  overlayMedium: '#000C20CC',
  cebia: '#5EDB3D',
} as const;

export const bmwTheme = {
  ...componentsTheme,
  radii: onlineOfferTheme.radii,
  fonts: onlineOfferTheme.fonts,
  fontWeights: onlineOfferTheme.fontWeights,
  fontSizes: onlineOfferTheme.fontSizes,
  lineHeights: onlineOfferTheme.lineHeights,
  letterSpacing: onlineOfferTheme.letterSpacing,
  devices: onlineOfferTheme.devices,
  zIndices: onlineOfferTheme.zIndices,
  icons: themeIcons,
  colors: {
    ...componentsTheme.colors,
    accent,
    text,
    fill,
    severity: onlineOfferTheme.colors.severity,
    border: onlineOfferTheme.colors.border,
  },
} as const;
