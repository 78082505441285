import {isNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {AuditCategory, RelatedAction} from '../api/digitalCertificateApi';
import {
  FeatureCategory,
  COMFORT_AND_CONVENIENCE_KEY,
  TRANSMISSION_AND_SUSPENSION_KEY,
  SECURITY_AND_SAFETY_KEY,
  ACCESSORIES_KEY,
} from '../consts/FeatureCategories';
import {DataUtilParams} from '../types/DataUtilParams';
import {getAuditFieldById} from './getAuditFieldById';
import {getCategoriesByUniqueKeys} from './getCategoriesByUniqueKeys';

export function getFeatures(params: DataUtilParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.auditStructure.categories) {
    return [];
  }

  const parentCategories = getCategoriesByUniqueKeys({
    parentUniqueKey: 'EQUIPMENT',
    uniqueKey: [
      COMFORT_AND_CONVENIENCE_KEY,
      TRANSMISSION_AND_SUSPENSION_KEY,
      SECURITY_AND_SAFETY_KEY,
      ACCESSORIES_KEY,
    ],
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!parentCategories?.length) {
    return [];
  }

  const categoriesWithFeatures = parentCategories.map((category) => ({
    title: category.name?.[params.language] ?? null,
    uniqueKey: category.uniqueKey as FeatureCategory,
    features:
      (category.paramDefinitions?.relatedActions
        ?.map((field) => ({
          title: field.values?.[0]?.label?.[params.language] ?? null,
          featureKey: field.values?.[0]?.featureKey ?? null,
        }))
        .filter(
          (feature) => isNotNilOrEmpty(feature.title) && isNotNilOrEmpty(feature.featureKey)
        ) as {
        // TS doesn't know that we've filtered out nulls
        title: string;
        featureKey: string;
      }[]) ?? [],
    id: category.key,
  }));

  return categoriesWithFeatures;
}

export const getComment = (
  relatedActions: RelatedAction[] | Nullish,
  auditCategories: AuditCategory[] | Nullish
) => {
  if (isNil(auditCategories) || isNil(relatedActions)) {
    return null;
  }
  const action = relatedActions?.find((i) => i.type === 'additional_comment');
  return getAuditFieldById(action?.id, auditCategories)?.value ?? null;
};
