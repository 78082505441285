import {ThemeRadiusPath} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {ReactNode} from 'react';

import {getToken} from 'shared';

import {ThemeColorPath} from '../../theme/theme';

type CardOoType = {
  backgroundColor?: ThemeColorPath;
  children?: ReactNode;
  radius?: ThemeRadiusPath;
};

export const CardOo = (props: CardOoType) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        border-radius: ${getToken('radii.' + (props.radius ?? 'medium'), theme)};
        background-color: ${getToken(
          'colors.' + (props.backgroundColor ?? 'semantic.bg.subtle'),
          theme
        )};
        padding: ${theme.getSize(5)};
      `}
    >
      {props.children}
    </div>
  );
};
