import {Box, Heading, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {Helmet} from 'react-helmet-async';

import {flatten, isNil, reject} from 'ramda';

import {CarouselSwiper} from '../../components/CarouselSwiper/CarouselSwiper';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {getCertificateHeader} from '../../utils/getCertificateHeader';
import {getPhotosByKeys} from '../../utils/getPhotosByKeys';
import {getExteriorPhotos} from './utils/getExteriorPhotos';
import {getInteriorPhotos} from './utils/getInteriorPhotos';

export function HeroNew() {
  const {vehicleAudit, vehicleCatalogue} = useGetOnlineOfferData();

  const exteriorPhotos = getExteriorPhotos(vehicleAudit);
  const interiorPhotos = getInteriorPhotos(vehicleAudit);
  const morePhotos = getPhotosByKeys({
    vehicleAudit,
    uniqueKey: 'MORE_PHOTOS',
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });

  const certificateHeader = getCertificateHeader({
    vehicle: vehicleAudit?.vehicle,
    vehicleCatalogue,
  });

  const allPhotos = flatten(reject(isNil, [exteriorPhotos, interiorPhotos, morePhotos]));

  const [heroLightboxControls, {onOpenBySlide}] = useLightbox('hero');

  return (
    <section id="hero">
      <Helmet>
        <title>{certificateHeader?.makeModel}</title>
      </Helmet>

      <Box width="100%" overflow="hidden" backgroundColor="fill.white">
        <VStack align="center">
          <Box
            width="100%"
            maxWidth={432}
            paddingTop={[4, 3, 3, 3]}
            paddingBottom={[4, 6, 6, 8]}
            paddingHorizontal={[3, 8, 8, 20]}
          >
            <Heading size={1}>
              {`${certificateHeader.makeModel} ${certificateHeader.specification}`}
            </Heading>
          </Box>
          <Box width="100%">
            <CarouselSwiper
              images={allPhotos}
              onInspectImage={(slide) => onOpenBySlide(slide, allPhotos)}
            />
          </Box>
          <Lightbox data-testid="heroAll" controls={heroLightboxControls} images={allPhotos} />
        </VStack>
      </Box>
    </section>
  );
}
