import {Grid, GridItem, useDevice} from 'platform/foundation';
import {formatNumber, useLocale} from 'platform/locale';
import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER} from 'shared';

import {SectionNew} from '../../components/SectionNew/SectionNew';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {SpecificationItemNew} from './components/SpecificationItemNew';
import {getSpecification} from './utils/getSpecification';

export function SpecificationNew() {
  const {vehicleAudit, alphaCatalogue} = useGetOnlineOfferData();
  const device = useDevice();
  const locale = useLocale();

  if (!vehicleAudit) {
    return null;
  }

  const specification = getSpecification(vehicleAudit, alphaCatalogue);

  const columns = match(device)
    .with('mobile', always(2))
    .with('tablet', always(3))
    .otherwise(always(4));

  const mileage = isNotNil(specification?.mileage)
    ? `${formatNumber(locale.localeConfig, specification.mileage, 0)} ${i18n.t('metricKm')}`
    : null;

  const power = isNotNil(specification?.power)
    ? `${specification.power} ${i18n.t('metricKw')}`
    : null;

  const co2Emissions = isNotNil(specification?.co2Emissions)
    ? `${specification.co2Emissions} ${i18n.t('metricGkm')}`
    : null;

  return (
    <SectionNew id="SPECIFICATION" heading="Specifikace" isWithBreakLine>
      <Grid columns={columns}>
        <GridItem span={1}>
          <SpecificationItemNew
            value={specification?.yearOfManufacture?.split('-')?.[0] ?? EMPTY_PLACEHOLDER}
            category="Rok výroby"
            icon="specification/year"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={mileage ?? EMPTY_PLACEHOLDER}
            category="Nájezd"
            icon="specification/mileage"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={specification?.bodywork ?? EMPTY_PLACEHOLDER}
            category="Karoserie"
            icon="specification/vehicle"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={specification?.differential ?? EMPTY_PLACEHOLDER}
            category="Pohon"
            icon="specification/drive_type"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={specification?.transmission ?? EMPTY_PLACEHOLDER}
            category="Převodovka"
            icon="specification/transmission"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={specification?.fuel ?? EMPTY_PLACEHOLDER}
            category="Palivo"
            icon="specification/fuel_type"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={power ?? EMPTY_PLACEHOLDER}
            category="Výkon"
            icon="specification/power"
          />
        </GridItem>
        <GridItem>
          <SpecificationItemNew
            value={co2Emissions ?? EMPTY_PLACEHOLDER}
            category="Emise CO2"
            icon="specification/co2"
          />
        </GridItem>
      </Grid>
    </SectionNew>
  );
}
