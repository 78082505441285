import {componentsTheme} from 'platform/components';

import {themeIcons} from '../assets/icons/themeIcons';
import {onlineOfferTheme} from './theme';

const accent = {
  darkest: '#150104',
  darker: '#6A0816',
  dark: '#A30C22',
  base: '#CE102C',
  light: '#F48D9C',
  lighter: '#FDE3E7',
  lightest: '#FFEDEF',
} as const;

const text = {
  primary: accent.darkest,
  secondary: '#42526E',
  tertiary: '#7A869A',
  inverted: '#FFFFFF',
  accent: accent.base,
  link: '#0047C1',
} as const;

const fill = {
  greyLight: '#F2F6F9',
  white: '#FFFFFF',
  overlayStrong: '#000C20F2',
  overlayMedium: '#000C20CC',
  cebia: '#5EDB3D',
} as const;

export const advantageCarTheme = {
  ...componentsTheme,
  radii: onlineOfferTheme.radii,
  fonts: onlineOfferTheme.fonts,
  fontWeights: onlineOfferTheme.fontWeights,
  fontSizes: onlineOfferTheme.fontSizes,
  lineHeights: onlineOfferTheme.lineHeights,
  letterSpacing: onlineOfferTheme.letterSpacing,
  devices: onlineOfferTheme.devices,
  zIndices: onlineOfferTheme.zIndices,
  icons: themeIcons,
  colors: {
    ...componentsTheme.colors,
    accent,
    text,
    fill,
    severity: onlineOfferTheme.colors.severity,
    border: onlineOfferTheme.colors.border,
  },
} as const;
