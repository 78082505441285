import {AuditDataAssetsFilesBody} from '../api/digitalCertificateApi';
import {SlideImage} from '../types/SlideImage';

export function mapAssetsToSlideImages(assets: AuditDataAssetsFilesBody[]): SlideImage[] {
  return assets.map((a) => ({
    type: 'image',
    id: `${a.imageId}_${a.url}`,
    url: a.url,
    resizeUrl: a.resizeUrl,
    fileName: a.imageId,
    meta: a.meta,
  }));
}
