import {VehicleAudit} from '../api/digitalCertificateApi';

export type TechnicalParamsDataBundle = {
  dimensions: {
    width: string | undefined;
    height: string | undefined;
    length: string | undefined;
  };
  details: {
    trunkSpace: string | undefined;
    weight: string | undefined;
    hitchWeight: string | undefined;
  };
};
export const useGetDataForTechnicalParams = (
  vehicleAudit: VehicleAudit | null | undefined
): TechnicalParamsDataBundle => ({
  dimensions: {
    width: vehicleAudit?.vehicle.dimensions?.width
      ? String(vehicleAudit.vehicle.dimensions.width)
      : undefined,
    height: vehicleAudit?.vehicle.dimensions?.height
      ? String(vehicleAudit.vehicle.dimensions.height)
      : undefined,
    length: vehicleAudit?.vehicle.dimensions?.length
      ? String(vehicleAudit.vehicle.dimensions.length)
      : undefined,
  },
  details: {
    trunkSpace: vehicleAudit?.vehicle.trunkSpace
      ? String(vehicleAudit.vehicle.trunkSpace)
      : undefined,
    weight: vehicleAudit?.vehicle.weight ? String(vehicleAudit.vehicle.weight) : undefined,
    // TODO: no existing property for this yet
    hitchWeight: undefined,
  },
});
