import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {getRatingCardParams} from '../(sections)/Hero/utils/getRatingCardParams';
import {
  getAllApiEnums,
  getVehicleAudit,
  getVehicleCatalogueByMake,
} from '../api/digitalCertificateApi';
import {queryParams} from '../consts/queryParams';
import {FALLBACK_LANGUAGE} from '../i18n/fallbackLanguage';
import {i18n} from '../i18n/i18n';
import {getWorkspaceFromUri} from '../utils/getWorkspaceFromUri';
import {useQueryState} from '../utils/useQueryState';

const {workspace} = getWorkspaceFromUri();

export function useGetOnlineOfferData() {
  const [language] = useQueryState(queryParams.LNG);
  const params = useParams<{auditId: string}>();

  const {data: alphaCatalogue} = useSelector(getAllApiEnums.select({lang: i18n.resolvedLanguage}));
  const {data: vehicleAudit} = useSelector(
    getVehicleAudit.select({
      auditId: params.auditId,
      workspace,
    })
  );
  const ratings = getRatingCardParams(vehicleAudit);

  const {data: vehicleCatalogue} = useSelector(
    getVehicleCatalogueByMake.select({
      vehicleType: vehicleAudit?.vehicle?.type,
      make: vehicleAudit?.vehicle?.make,
      lang: language ?? i18n.resolvedLanguage ?? FALLBACK_LANGUAGE,
    })
  );

  return {
    vehicleAudit,
    alphaCatalogue,
    vehicleCatalogue,
    auditId: params.auditId,
    ratings,
  };
}
