import {Box, Heading, Image, Stack, useDevice, VStack} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {EMPTY_PLACEHOLDER} from 'shared';

import {SectionNew} from '../../components/SectionNew/SectionNew';
import {TechnicalParamsDataBundle} from '../../hooks/useGetDataForTechnicalParams';
import {TechnicalParametersItem} from '../TechnicalParametersItem/TechnicalParametersItem';

type TechnicalParametersProps = {
  data: TechnicalParamsDataBundle;
};

export const TechnicalParameters = ({data}: TechnicalParametersProps) => {
  const device = useDevice();
  // left box
  const heightArrowLeftPosition = match(device)
    .with('mobile', always(1 as const))
    .with('tablet', always(1 as const))
    .with('notebook', always(1 as const))
    .otherwise(always(1 as const));
  const heightArrowTopPosition = match(device)
    .with('mobile', always(2 as const))
    .with('tablet', always(3 as const))
    .with('notebook', always(3 as const))
    .otherwise(always(6 as const));
  // center box
  const lengthArrowLeftPosition = match(device)
    .with('mobile', always(3 as const))
    .with('tablet', always(3 as const))
    .with('notebook', always(3 as const))
    .otherwise(always(6 as const));
  const lengthArrowBottomPosition = match(device)
    .with('mobile', always(5 as const))
    .with('tablet', always(2 as const))
    .with('notebook', always(2 as const))
    .otherwise(always(4 as const));
  // right box
  const widthArrowLeftPosition = match(device)
    .with('mobile', always(15 as const))
    .with('tablet', always(18 as const))
    .with('notebook', always(18 as const))
    .otherwise(always(29.5 as const));
  const widthArrowBottomPosition = match(device)
    .with('mobile', always(3.7 as const))
    .with('tablet', always(0 as const))
    .with('notebook', always(0 as const))
    .otherwise(always(0.5 as const));
  return (
    <SectionNew id="TECHNICAL_PARAMETERS" heading="Technické parametry">
      <Stack justify="space-between" direction={['column', 'row']}>
        <Box paddingLeft={9} position="relative" paddingBottom={[16, 0]}>
          <HeightArrowContainer left={heightArrowLeftPosition} top={heightArrowTopPosition}>
            <Heading size={5} headingLevel={4} color="secondary" align="center">
              {`${data.dimensions.height || EMPTY_PLACEHOLDER} mm`}
            </Heading>
          </HeightArrowContainer>
          <LengthArrowContainer left={lengthArrowLeftPosition} bottom={lengthArrowBottomPosition}>
            <Heading size={5} headingLevel={4} color="secondary" align="center">
              {`${data.dimensions.length || EMPTY_PLACEHOLDER} mm`}
            </Heading>
          </LengthArrowContainer>
          <WidthArrowContainer left={widthArrowLeftPosition} bottom={widthArrowBottomPosition}>
            <Heading size={5} headingLevel={4} color="secondary" align="center">
              {`${data.dimensions.width || EMPTY_PLACEHOLDER} mm`}
            </Heading>
          </WidthArrowContainer>
          <Image
            borderRadius="small"
            src="../../assets/images/Vehicle.svg"
            height="100%"
            width={[65, 80, 80, 136]}
            isLazy
            hasSpinner
          />
        </Box>
        <Box paddingRight={[0, 0, 0, 16]}>
          <VStack spacing={4}>
            <TechnicalParametersItem
              value={`${data.details.trunkSpace || EMPTY_PLACEHOLDER} l`}
              icon="technicalParams/trunk"
              title="Objem zavazadlového prostoru"
            />
            <TechnicalParametersItem
              value={`${data.details.weight || EMPTY_PLACEHOLDER} kg`}
              icon="technicalParams/weight"
              title="Hmotnost"
            />
            <TechnicalParametersItem
              value={`${data.details.hitchWeight || EMPTY_PLACEHOLDER} kg`}
              icon="technicalParams/towing"
              title="Max. hmotnost tažného zařízení"
            />
          </VStack>
        </Box>
      </Stack>
    </SectionNew>
  );
};

const HeightArrowContainer = styled.div<{left: number; top: number}>`
  position: absolute;
  z-index: ${({theme}) => theme.zIndices.CONTENT};
  left: ${({left}) => left}rem;
  top: ${({top}) => top}rem;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
`;

const LengthArrowContainer = styled.div<{left: number; bottom: number}>`
  position: absolute;
  z-index: ${({theme}) => theme.zIndices.CONTENT};
  left: ${({left}) => left}rem;
  bottom: ${({bottom}) => bottom}rem;
`;

const WidthArrowContainer = styled.div<{left: number; bottom: number}>`
  position: absolute;
  z-index: ${({theme}) => theme.zIndices.CONTENT};
  left: ${({left}) => left}rem;
  bottom: ${({bottom}) => bottom}rem;
`;
