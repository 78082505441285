import {ComponentsProvider} from 'platform/components';
import {AppLanguage, getDefaultLocaleByAppLanguage, LocaleProvider} from 'platform/locale';
import {StyleSheetManager} from 'styled-components';

import {ReactNode} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import {GlobalStyles} from '../../GlobalStyles';
import {i18n} from '../../i18n/i18n';
import {store} from '../../store';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';

interface MainBoundaryProps {
  children: ReactNode;
}

export function MainBoundary(props: MainBoundaryProps) {
  const {theme} = getWorkspaceCustomization();

  return (
    <Provider store={store}>
      <StyleSheetManager enableVendorPrefixes>
        <ComponentsProvider theme={theme} t={i18n.t} language={i18n.language}>
          <HelmetProvider>
            <LocaleProvider
              language={i18n.resolvedLanguage}
              config={getDefaultLocaleByAppLanguage(i18n.resolvedLanguage as AppLanguage)}
            >
              {props.children}
            </LocaleProvider>
            <GlobalStyles />
          </HelmetProvider>
        </ComponentsProvider>
      </StyleSheetManager>
    </Provider>
  );
}
