import {Box, HStack, Right, Space, Text, VStack} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {useEffect} from 'react';

import {keys} from 'ramda';
import {isTrue} from 'ramda-adjunct';

import {languageDialogItems} from '../../consts/languageDialogItems';
import {queryParams} from '../../consts/queryParams';
import {i18n} from '../../i18n/i18n';
import {useQueryState} from '../../utils/useQueryState';
import {ButtonIcon} from '../ButtonIcon/ButtonIcon';
import {LanguageItem} from '../Footer/components/LanguageItem';

interface LanguageDialogProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
}

export function LanguageDialog(props: LanguageDialogProps) {
  const [_, setLanguage] = useQueryState(queryParams.LNG);

  useEffect(() => {
    if (isTrue(props.isVisible)) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [props.isVisible]);

  const onChangeLanguage = (lang: AppLanguage) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLanguage(lang);
        window.location.reload();
      })
      .catch(() => {
        throw new Error('fatal error - failed to set the application language');
      });
  };
  return (
    <Box top={0} position="fixed" zIndex="LANGUAGE_DIALOG">
      <Animation $isVisible={props.isVisible}>
        <Box
          width="100%"
          height="100%"
          padding={6}
          backgroundColor="general.white"
          overflowY="auto"
          overflowX="hidden"
        >
          <VStack minHeight="100%" justify="space-between" spacing={7}>
            <HStack align="center" justify="space-between">
              <Text alternative size="large">
                {props.title}
              </Text>
              <Right>
                <ButtonIcon onClick={props.onClose} icon="close" variant="outline" />
              </Right>
            </HStack>

            <VStack spacing={1}>
              {keys(languageDialogItems).map((lang) => (
                <LanguageItem
                  key={lang}
                  icon={languageDialogItems[lang].icon}
                  title={languageDialogItems[lang].title}
                  onClick={() => onChangeLanguage(lang)}
                  isItemSelected={i18n.resolvedLanguage === lang}
                />
              ))}
            </VStack>
            <Space vertical={4} fillAvailable />
          </VStack>
        </Box>
      </Animation>
    </Box>
  );
}

const Animation = styled.div<{$isVisible: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  height: fill-available;
  transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1) 0.05s;
  transform: ${({$isVisible}) => ($isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)')};
`;
