import {HStack, Show, Text, TextProps} from 'platform/foundation';

import {ReactNode} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

type TimerRowType = {
  label: string;
  labelProps?: TextProps;
  value?: string | ReactNode;
  valueProps?: TextProps;
};
export const ReservationRow = (props: TimerRowType) => {
  return (
    <HStack justify="space-between" align="center">
      <Text size="xSmall" {...props.labelProps}>
        {props.label}
      </Text>
      <Show when={isNotNilOrEmpty(props.value)}>
        {typeof props.value === 'string' ? (
          <Text size="xSmall" {...props.valueProps}>
            {props.value}
          </Text>
        ) : (
          props.value
        )}
      </Show>
    </HStack>
  );
};
