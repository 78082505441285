import {Separator} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {Button} from '../../components/Button/Button';
import {CardOo} from '../../components/CardOo/CardOo';
import {ReservationRow} from './ReservationRow';

type ReservationCardType = {
  isExpired?: boolean;
};

export function ReservationCard(props: ReservationCardType) {
  return (
    <CardOo backgroundColor="semantic.bg.subtle">
      <VStack width="100%" spacing={5}>
        <VStack spacing={2}>
          <ReservationRow
            label="Cena celkem"
            value={<Heading size={3}>1 831 314 Kč</Heading>}
            labelProps={{alternative: true}}
          />
          <ReservationRow label="Cena celkem bez DPH" value="1 831 314 Kč" />
        </VStack>
        <VStack spacing={2}>
          <Button
            leftIcon="good"
            title="Mám zájem o vůz"
            data-testid="reservationCard-resrve"
            variant="primary"
          />
          <Button
            leftIcon={props.isExpired ? 'specification/year' : 'navigation/cancel'}
            leftIconSize={4}
            title={props.isExpired ? 'Rezervovat vůz' : 'Zrušit rezervaci'}
            data-testid="reservationCard-cancel"
            variant="outline"
          />
        </VStack>
        <Separator spacing={0} />
        <VStack spacing={2}>
          <ReservationRow
            label="Cena vozu"
            labelProps={{alternative: true}}
            value={<Heading size={4}>2 450 000 Kč</Heading>}
          />
          <ReservationRow label="Cena vozu bez DPH" value="2 024 793 Kč" />
          <ReservationRow
            label="Původní cena vozu"
            value="2 800 000 Kč"
            valueProps={{color: 'danger', strikethrough: true}}
          />
          <ReservationRow
            label="Zákaznická sleva 10 %"
            value="-245 000 Kč"
            valueProps={{color: 'success'}}
          />
        </VStack>
        <Separator spacing={0} />
        <VStack spacing={2}>
          <ReservationRow label="Doplňkový prodej" labelProps={{alternative: true}} />
          <ReservationRow label="Prodloužená záruka" value="5 990 Kč" />
          <ReservationRow label="Sada autokosmetiky" value="1 990 Kč" />
          <ReservationRow label="Mytí vozu" value="970 Kč" />
        </VStack>
        <CardOo backgroundColor="general.white" radius="small">
          <ReservationRow
            label="Při financování od"
            labelProps={{alternative: true}}
            value={<Heading size={4}>26 786 Kč/měs</Heading>}
          />
          <Separator spacing={2} />
          <ReservationRow label="Při financování od" value="60 měsíců (5 let)" />
        </CardOo>
      </VStack>
    </CardOo>
  );
}
