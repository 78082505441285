import {Integer, ThemeIconKey, ValueByDevice, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {CardOo} from '../../components/CardOo/CardOo';
import {Flag} from '../../components/Flag/Flag';
import {ThemeColorPath} from '../../theme/theme';
import {Timer} from './Timer';

export type TimerState = 'reserved' | 'soon_expired' | 'expired';

const getFlagProps = (timerState: TimerState) =>
  match<
    TimerState,
    {
      icon: ThemeIconKey;
      color: ThemeColorPath;
      text: string;
      cardBg: ThemeColorPath;
      iconSize: Integer | ValueByDevice<Integer>;
    }
  >(timerState)
    .with(
      'reserved',
      always({
        icon: 'good',
        color: 'severity.successBase',
        text: 'Vůz je pro Vás rezervován',
        cardBg: 'semantic.bg.green',
        iconSize: 5,
      })
    )
    .with(
      'soon_expired',
      always({
        icon: 'damage',
        color: 'severity.warningDark',
        text: 'Vaše rezervace brzy vyprší',
        cardBg: 'severity.warningCard',
        iconSize: 4,
      })
    )
    .with(
      'expired',
      always({
        icon: 'missing',
        color: 'severity.dangerBase',
        text: 'Vaše rezervace už bohužel vypršela',
        cardBg: 'severity.dangerCard',
        iconSize: 5,
      })
    )
    .otherwise(
      always({
        icon: 'good',
        color: 'severity.successBase',
        text: 'Vůz je pro Vás rezervován',
        cardBg: 'semantic.bg.green',
        iconSize: 5,
      })
    );

export type TimerStateType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  state: TimerState;
};

type TimerCardType = {
  timer: TimerStateType;
};

export const TimerCard = ({timer}: TimerCardType) => {
  const flagProps = getFlagProps(timer.state);

  return (
    <CardOo backgroundColor={flagProps.cardBg}>
      <VStack align="flex-start" spacing={5}>
        <Flag
          iconSize={flagProps.iconSize}
          size="small"
          icon={flagProps.icon}
          color={flagProps.color}
          text={flagProps.text}
          data-testid="timerCard"
        />
        <Timer
          days={timer.days}
          hours={timer.hours}
          seconds={timer.seconds}
          minutes={timer.minutes}
        />
      </VStack>
    </CardOo>
  );
};
