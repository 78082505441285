import {Heading, Image, Text, VStack} from 'platform/foundation';
import {css} from 'styled-components';

import {i18n} from '../../i18n/i18n';

export function Error() {
  return (
    <section
      css={css`
        min-width: 100vw;
        min-height: 100vh;
        min-height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${({theme}) => theme.getSize(3)};
      `}
    >
      <VStack spacing={6} align="center">
        <Image src="./assets/images/logo.svg" alt="Online Offer" height={12} width="auto" isLazy />
        <VStack align="center">
          <Heading size={2}>{i18n.t('errorMessage')}</Heading>
          <Text size="small" color="tertiary">
            {i18n.t('errorMessageDescription')}
          </Text>
        </VStack>
      </VStack>
    </section>
  );
}
