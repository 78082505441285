import {Box, HStack, Space, Text} from 'platform/foundation';
import {css} from 'styled-components';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

type Data = {
  label: string;
  value: number | string | Nullish;
  colorCode?: string;
};

export type DetailsColumnProps = {
  data: Data[];
};

export const DetailsColumn = (props: DetailsColumnProps) => (
  <>
    {props.data.map(({label, value, colorCode}) => (
      <Box key={label + value} paddingVertical={2}>
        <HStack key={label + value}>
          <Box flex={1}>
            <Text size="small">{label}</Text>
          </Box>
          <Box flex={1}>
            <HStack align="center">
              {colorCode && (
                <>
                  <div
                    css={css`
                      border-radius: ${({theme}) => theme.radii.medium};
                      border: 1px solid ${({theme}) => theme.colors.palettes.neutral[40][100]};
                      background-color: ${colorCode};
                      width: ${({theme}) => theme.getSize(4)};
                      height: ${({theme}) => theme.getSize(4)};
                    `}
                  />
                  <Space horizontal={2} />
                </>
              )}
              <Text alternative size="small">
                {String(value ?? EMPTY_PLACEHOLDER)}
              </Text>
            </HStack>
          </Box>
        </HStack>
      </Box>
    ))}
  </>
);
