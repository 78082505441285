export const COMFORT_AND_CONVENIENCE_KEY = 'FEATURE_CATEGORY_COMFORT_CONVENIENCE';
export const TRANSMISSION_AND_SUSPENSION_KEY = 'FEATURE_CATEGORY_TRANSMISSION_AND_SUSPENSION';
export const SECURITY_AND_SAFETY_KEY = 'FEATURE_CATEGORY_SECURITY_SAFETY_ASSISTANCE';
export const ACCESSORIES_KEY = 'FEATURE_CATEGORY_ACCESSORIES_AND_EXTRA_FEATURES';

export const FEATURE_CATEGORIES = [
  COMFORT_AND_CONVENIENCE_KEY,
  SECURITY_AND_SAFETY_KEY,
  TRANSMISSION_AND_SUSPENSION_KEY,
  ACCESSORIES_KEY,
] as const;

export type FeatureCategory = (typeof FEATURE_CATEGORIES)[number];
