import {
  Center,
  HStack,
  Icon,
  Integer,
  Show,
  Text,
  ThemeColorPath,
  ThemeIconKey,
  ValueByDevice,
} from 'platform/foundation';
import {css, useTheme} from 'styled-components';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {getToken, suffixTestId, TestIdProps} from 'shared';

type FlagSize = 'default' | 'small';

export interface FlagProps extends TestIdProps {
  text?: string;
  icon?: ThemeIconKey;
  iconSize?: Integer | ValueByDevice<Integer>;
  size?: FlagSize;
  color?: ThemeColorPath;
}

export function Flag(props: FlagProps) {
  const theme = useTheme();

  const hasText = isNotNilOrEmpty(props.text);
  const isSmall = props.size === 'small';
  const height = isSmall ? 6 : 10;
  const paddingHorizontal = isSmall ? 2 : 4;

  return (
    <div
      css={css`
        padding-left: ${theme.getSize(paddingHorizontal)};
        padding-right: ${theme.getSize(paddingHorizontal)};
        width: ${hasText ? 'auto' : theme.getSize(height)};
        border-radius: ${theme.radii.large};
        background-color: ${getToken('colors.' + (props.color ?? 'accent.base'), theme)};
      `}
      data-testid={suffixTestId('flag', props)}
    >
      <Center height={height}>
        <HStack align="center" spacing={2}>
          <Icon value={props.icon} size={props.iconSize ?? 5} color="fill.white" />
          <Show when={hasText}>
            <Text size={isSmall ? 'xSmall' : 'small'} color="inverted">
              {props.text}
            </Text>
          </Show>
        </HStack>
      </Center>
    </div>
  );
}
