import {Box, HStack, Show, Text, VStack} from 'platform/foundation';
import {css} from 'styled-components';

import {isNil, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {FeatureStateFlag, FeatureStateFlagSeverity} from '../../FeatureStateFlag/FeatureStateFlag';

export type ListFeaturesItemVariant = FeatureStateFlagSeverity;
export type ListFeatureFlagItem = Pick<ListFeaturesItemProps, 'variant' | 'flagText'>;

export interface ListFeaturesItemProps extends TestIdProps {
  title: string;
  value?: string | Nullish;
  description?: string | Nullish;
  variant: ListFeaturesItemVariant;
  flagText?: string | Nullish;
  flagTexts?: ListFeatureFlagItem[] | Nullish;
  hidden?: boolean;
  uniqueKey?: string;
}

export function ListFeaturesItem(props: ListFeaturesItemProps) {
  if (isNil(props.title)) {
    return null;
  }

  return (
    <div
      css={css`
        break-inside: avoid-column;
      `}
    >
      <HStack spacing={3} data-testid={suffixTestId('listFeaturesItem', props)} align="flex-start">
        <FeatureStateFlag severity={props.variant} />
        <VStack spacing={1}>
          <HStack spacing={2} align="center" wrap>
            <Box paddingRight={1}>
              <Text alternative>{props.title}</Text>
            </Box>
            <Show when={props.flagText && props.variant !== 'ready'}>
              <FeatureStateFlag text={props.flagText} severity={props.variant} />
            </Show>
            <Show when={isNotNilOrEmpty(props.value)}>
              <Text size="small">{props.value}</Text>
            </Show>
            <Show when={isNotNilOrEmpty(props.flagTexts) && props.variant !== 'ready'}>
              <HStack spacing={2} wrap>
                {props.flagTexts?.map((flag) => (
                  <FeatureStateFlag
                    key={`${flag.flagText}-${flag.variant}`}
                    text={flag.flagText}
                    severity={flag.variant}
                  />
                ))}
              </HStack>
            </Show>
          </HStack>
          <Show when={isNotNil(props.description)}>
            <Text size="xSmall" color="secondary">
              {props.description}
            </Text>
          </Show>
        </VStack>
      </HStack>
    </div>
  );
}
