import i18n from 'i18next';

import {Nullish} from 'shared';

import {VehicleAudit} from '../../../api/digitalCertificateApi';

export const getRatingCardParams = (vehicleAudit: VehicleAudit | Nullish) => {
  if (!vehicleAudit) {
    return null;
  }
  const ratings = vehicleAudit?.auditStructure.ratings.map((rating) => ({
    paramDefinitionId: rating.paramDefinitionId,
    name: rating.name,
  }));

  const hasAtLeastOneRating = ratings.length > 0;

  if (!hasAtLeastOneRating) {
    return null;
  }

  const ratingsResult = vehicleAudit?.audit.categories
    .map((category) => {
      const rating = ratings?.find((item) => item.paramDefinitionId === category.paramDefinitionId);
      if (rating && category.type === 'field') {
        return {...category, ...rating};
      }
      return null;
    })
    .filter(Boolean);
  const exteriorRating = ratingsResult?.filter(
    (rating) => rating?.name === 'overallEvaluationExterior'
  )[0]?.value;
  const interiorRating = ratingsResult?.filter(
    (rating) => rating?.name === 'overallEvaluationInterior'
  )[0]?.value;
  const mechanicRating = ratingsResult?.filter(
    (rating) => rating?.name === 'overallEvaluationTechnical'
  )[0]?.value;

  return {
    exterior: {
      title: i18n.t('exteriorHeader'),
      rating: exteriorRating ? Number(exteriorRating) : undefined,
      icon: 'exterior',
    },
    interior: {
      title: i18n.t('interiorHeader'),
      rating: interiorRating ? Number(interiorRating) : undefined,
      icon: 'steeringWheel',
    },
    mechanic: {
      title: i18n.t('mechanicsHeader'),
      rating: mechanicRating ? Number(mechanicRating) : undefined,
      icon: 'mechanics',
    },
  } as const;
};
