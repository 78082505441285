export type SectionIdsType = (typeof SectionsNew)[number]['id'];

export const SectionsNew = [
  {
    id: 'SPECIFICATION',
    menuTitle: 'Specifikace',
  },
  {
    id: 'TECHNICAL_PARAMETERS',
    menuTitle: 'Technické parametry',
  },
  {
    id: 'FEATURES',
    menuTitle: 'Výbava',
  },
  {
    id: 'DETAILS',
    menuTitle: 'Detaily',
  },
  {id: 'CERTIFICATE', menuTitle: 'Stav vozu'},
] as const;

export type SectionId = (typeof SectionsNew)[number]['id'];
