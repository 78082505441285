import {Show, Space, Text, useResponsivePropValue, Grid, GridItem} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {CommentExpandable} from '../../components/CommentExpandable/CommentExpandable';
import {SectionNew} from '../../components/SectionNew/SectionNew';
import {DetailsDataBundle} from '../../hooks/useGetDataForDetails';
import i18n from '../../i18n/i18n';
import {DetailsColumn} from './DetailsColumn';

type FeaturesProps = {
  data: DetailsDataBundle | null;
};

export function Details(props: FeaturesProps) {
  const gridColumns = useResponsivePropValue([1, 2]);
  const formatDateTime = useDateTimeFormatter();
  const {data} = props;
  if (!data) {
    return null;
  }
  return (
    <SectionNew id="DETAILS" heading="Detaily" isWithBreakLine>
      <Grid columns={gridColumns} spacing={8}>
        <GridItem>
          <DetailsColumn
            data={[
              {label: 'VIN', value: data.vin},
              {
                label: 'První registrace',
                value: data.firstRegistration
                  ? formatDateTime('dateShort', parseDate(data.firstRegistration))
                  : '',
              },
              {label: 'Země původu', value: data.countryOfOrigin},
              {label: 'Typ barvy', value: data.typeOfColour},
              {label: 'Název barvy výrobce', value: data.nameOfColour},
              {
                label: 'Barva karoserie',
                value: data.exteriorColour?.name,
                colorCode: data.exteriorColour.value ?? undefined,
              },
              {
                label: 'Barva interiéru',
                value: data.interiorColour.name,
                colorCode: data.interiorColour.value ?? undefined,
              },
              {label: 'Materiál interiéru', value: data.interiorMaterial},
              {label: 'Počet míst', value: data.numberOfSeats},
              {label: 'Počet dveří', value: data.numberOfDoors},
              {label: 'Počet klíčů', value: data.numberOfKeys},
              {label: 'Počet majitelů', value: data.numberOfOwners},
            ]}
          />
        </GridItem>
        <GridItem>
          <DetailsColumn
            data={[
              {label: 'Typ hybridu', value: data.hybridType},
              {label: 'Sekundární palivo', value: data.secondaryFuel},
              {label: 'Emisní třída', value: data.emissionClass},
              {label: 'Spotřeba - kombinovaná', value: data.fuelUsageCombined},
              {label: 'Spotřeba elektřiny - kombinovaná', value: data.electricalUsageCombined},
              {label: 'Výkon spalovacího motoru (kW)', value: data.enginePower},
              {label: 'Výkon elektrického motoru (kW)', value: data.electricalEnginePower},
              {label: 'Zdvihový moment (ccm)', value: data.engineDisplacement},
              {label: 'Počet válců', value: data.numberOfPistons},
              {label: 'Počet převodových stupňů', value: data.numberOfGears},
            ]}
          />
        </GridItem>
      </Grid>
      <Space vertical={7} />
      <Show when={isNotNilOrEmpty(data.sellerNote)}>
        <Text size="large" alternative>
          {i18n.t('detailsInfo')}
        </Text>
        <Space vertical={2} />
        <CommentExpandable hideCommentFlag comment={data.sellerNote!} />
      </Show>
    </SectionNew>
  );
}
