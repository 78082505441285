import {getValueByDevice, Icon, Integer, useDevice} from 'platform/foundation';
import styled from 'styled-components';
import {useSwiper} from 'swiper/react';

import {useBoolean} from 'shared';

interface CarouselButtonProps {
  variant: 'right' | 'left';
}

export const CarouselButton = (props: CarouselButtonProps) => {
  const [isHovered, startHover, stopHover] = useBoolean();
  const swiper = useSwiper();
  const device = useDevice();
  const buttonWidth = getValueByDevice(device, 7, 9, 13, 13);
  return (
    <ButtonWrapper
      onMouseEnter={startHover}
      onMouseLeave={stopHover}
      $variant={props.variant}
      $buttonWidth={buttonWidth}
      $isHovered={isHovered}
      onClick={() => {
        if (props.variant === 'left') {
          swiper.slidePrev();
        } else if (props.variant === 'right') {
          swiper.slideNext();
        }
      }}
    >
      <Icon
        value={props.variant === 'right' ? 'navigation/chevron_right' : 'navigation/chevron_left'}
        color="general.white"
        size={6}
      />
    </ButtonWrapper>
  );
};

interface ButtonWrapperProps {
  $variant: CarouselButtonProps['variant'];
  $buttonWidth: Integer;
  $isHovered: boolean;
}

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  position: absolute;
  ${({$variant}) => ($variant === 'left' ? 'left: 0;' : '')}
  ${({$variant}) => ($variant === 'right' ? 'right: 0;' : '')}
  top: 0;
  height: 100%;
  transition: background-color 250ms ease;
  width: ${({theme, $buttonWidth}) => theme.getSize($buttonWidth)};
  background-color: ${({theme, $isHovered}) =>
    $isHovered ? theme.colors.fill.overlayStrong : theme.colors.palettes.black['900']['40']};
  z-index: ${({theme}) => theme.zIndices.SWIPER_BUTTON};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
