import {Box, HStack, Show, Space, VStack} from 'platform/foundation';
import styled, {createGlobalStyle} from 'styled-components';

import {useEffect} from 'react';

import {isTrue} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {SECTIONS, SectionId} from '../../../consts/Sections';
import {ButtonIcon} from '../../ButtonIcon/ButtonIcon';
import {isSectionActive} from '../../Section/utils/getActiveSections';
import {NavigationItem} from './NavigationItem';

export interface MobileNavigationProps extends TestIdProps {
  sections: string[];
  isVisible: boolean;
  onClose: VoidFunction;
  onShareClick: VoidFunction;
  onDownloadClick: VoidFunction;
  isPdfDownloading: boolean;
}

export function MobileNavigation(props: MobileNavigationProps) {
  useEffect(() => {
    if (isTrue(props.isVisible)) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [props.isVisible]);

  const navigateToSection = (sectionId: SectionId) => {
    const sectionElement = document.querySelector(`#${sectionId}`);
    if (!sectionElement) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        sectionElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        MOBILE_MENU_HEIGHT,
    });

    props.onClose();
  };

  const sections = SECTIONS.filter((section) => isSectionActive(section.id, props.sections));

  return (
    <Box
      top={0}
      position="fixed"
      zIndex="MOBILE_NAVIGATION"
      data-testid={suffixTestId('mobileNavigation', props)}
    >
      <Animation $isVisible={props.isVisible}>
        <Box
          width="100%"
          height="100%"
          padding={6}
          backgroundColor="general.white"
          overflowY="auto"
          overflowX="hidden"
        >
          <VStack minHeight="100%" justify="space-between">
            <VStack spacing={1}>
              {sections.map((section) => (
                <NavigationItem
                  key={section.id}
                  title={section.menuTitle}
                  onClick={() => navigateToSection(section.id)}
                />
              ))}
            </VStack>
            <Space vertical={4} fillAvailable />
            <HStack spacing={2}>
              <ButtonIcon
                variant="outline"
                icon="download"
                onClick={props.onDownloadClick}
                isLoading={props.isPdfDownloading}
              />

              <Show when={navigator.share}>
                <ButtonIcon variant="outline" icon="share" onClick={props.onShareClick} />
              </Show>
              <Space horizontal={4} fillAvailable />
              <ButtonIcon onClick={props.onClose} icon="close" variant="outline" />
            </HStack>
          </VStack>
        </Box>
      </Animation>
      <BodyStyle $isMenuVisible={props.isVisible} />
    </Box>
  );
}

const MOBILE_MENU_HEIGHT = 30;

const Animation = styled.div<{$isVisible: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1) 0.05s;
  transform: ${({$isVisible}) => ($isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)')};
`;
const BodyStyle = createGlobalStyle<{$isMenuVisible: boolean}>`
  body {
    overflow-y: ${({$isMenuVisible}) => ($isMenuVisible ? 'hidden' : 'auto')};
    transition: left 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    left: ${({$isMenuVisible}) => ($isMenuVisible ? '-200px' : '0')};
  }
`;
