import {
  Box,
  getValueByDevice,
  Heading,
  Hide,
  HStack,
  Image,
  Show,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import {css, DefaultTheme, useTheme} from 'styled-components';

import {useEffect, useMemo, useRef} from 'react';

import {__, all, filter, gt, gte, keys, length, pipe, values} from 'ramda';
import {isNotNilOrEmpty, isPositive, isPositiveZero, isTrue, toNumber} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import ExteriorBackOverlay from '../../assets/images/exterior-back-overlay.svg';
import ExteriorFrontOverlay from '../../assets/images/exterior-front-overlay.svg';
import PaintJob from '../../assets/images/paintjob.svg';
import {BannerSuccess} from '../../components/BannerSuccess/BannerSuccess';
import {CommentMechanic} from '../../components/CommentMechanic/CommentMechanic';
import {DamageCarouselPrint} from '../../components/DamageCarousel/DamageCarousel.print';
import {getDamageCarouselDataFromMap} from '../../components/DamageCarousel/utils/getDamageCarouselDataFromMap';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {Separator} from '../../components/Separator/Separator';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {ValueWithUnit} from '../../types/ValueWithUnit';
import {getAuditAssignee} from '../../utils/getAuditAssignee';
import {getDamageValues} from '../../utils/getDamageValues';
import {getRecordsTranslate} from '../../utils/getRecordsTranslate';
import {getValueWithUnit} from '../../utils/getValueWithUnit';
import {getExterior} from './utils/getExterior';

export function ExteriorPrint() {
  const device = useDevice();
  const theme = useTheme();
  const damagesRef = useRef<HTMLDivElement>(null);
  const paintRef = useRef<HTMLDivElement>(null);

  const locale = useLocale();

  const {vehicleAudit} = useGetOnlineOfferData();
  const assignee = getAuditAssignee(vehicleAudit);

  const damageValues = useMemo(
    () => ({
      leftFrontLight: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_LIGHT',
        point: 'leftFrontLight',
      }),

      rightFrontLight: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_LIGHT',
        point: 'rightFrontLight',
      }),

      frontBumper: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'FRONT_BUMPER',
        point: 'frontBumper',
      }),

      leftFrontFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_FENDER',
        point: 'leftFrontFender',
      }),

      rightFrontFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_FENDER',
        point: 'rightFrontFender',
      }),

      leftRearFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LR_FENDER',
        point: 'leftRearFender',
      }),

      rightRearFender: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RR_FENDER',
        point: 'rightRearFender',
      }),

      frontHood: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'FRONT_HOOD',
        point: 'frontHood',
      }),

      leftFrontDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LF_DOOR',
        point: 'leftFrontDoor',
      }),

      rightFrontDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RF_DOOR',
        point: 'rightFrontDoor',
      }),

      leftRearDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'LR_DOOR',
        point: 'leftRearDoor',
      }),

      rightRearDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'RR_DOOR',
        point: 'rightRearDoor',
      }),

      leftMirror: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'L_MIRROR',
        point: 'leftMirror',
      }),

      rightMirror: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'R_MIRROR',
        point: 'rightMirror',
      }),

      frontWindow: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'WINDSHIELD',
        point: 'frontWindow',
      }),

      roof: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'ROOF',
        point: 'roof',
      }),

      backDoor: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'THE_SUITCASE_LID',
        point: 'backDoor',
      }),

      backBumper: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'REAR_BUMPER',
        point: 'backBumper',
      }),

      other: getDamageValues({
        vehicleAudit,
        language: locale.language,
        parentUniqueKey: 'EXTERIOR',
        uniqueKey: 'OTHER',
        point: 'other',
      }),
    }),
    [locale.language, vehicleAudit]
  );

  const damagesData = useMemo(
    () => ({
      leftFrontFender: damageValues.leftFrontFender?.values?.length ?? 0,
      backBumper: damageValues.backBumper?.values?.length ?? 0,
      rightFrontDoor: damageValues.rightFrontDoor?.values?.length ?? 0,
      rightFrontFender: damageValues.rightFrontFender?.values?.length ?? 0,
      frontWindow: damageValues.frontWindow?.values?.length ?? 0,
      frontHood: damageValues.frontHood?.values?.length ?? 0,
      frontBumper: damageValues.frontBumper?.values?.length ?? 0,
      rightFrontLight: damageValues.rightFrontLight?.values?.length ?? 0,
      rightMirror: damageValues.rightMirror?.values?.length ?? 0,
      leftMirror: damageValues.leftMirror?.values?.length ?? 0,
      leftFrontLight: damageValues.leftFrontLight?.values?.length ?? 0,
      roof: damageValues.roof?.values?.length ?? 0,
      rightBackDoor: damageValues.rightRearDoor?.values?.length ?? 0,
      rightBackFender: damageValues.rightRearFender?.values?.length ?? 0,
      leftFrontDoor: damageValues.leftFrontDoor?.values?.length ?? 0,
      leftBackDoor: damageValues.leftRearDoor?.values?.length ?? 0,
      leftBackFender: damageValues.leftRearFender?.values?.length ?? 0,
      backDoor: damageValues.backDoor?.values?.length ?? 0,
    }),
    [damageValues]
  );

  const exterior = getExterior({vehicleAudit, language: locale.language});

  useEffect(() => {
    if (!damagesData || !exterior?.paintData) {
      return;
    }
    showDamagesOverlay(damagesData);
    showPaintOverlay(exterior.paintData, theme);
  }, [damagesData, exterior, exterior?.paintData, theme]);

  const carouselData = getDamageCarouselDataFromMap(Object.values(damageValues));

  const isStatusOkay = all(isPositiveZero, [
    damageValues.backBumper?.values?.length,
    damageValues.backDoor?.values?.length,
    damageValues.frontBumper?.values?.length,
    damageValues.frontHood?.values?.length,
    damageValues.frontWindow?.values?.length,
    damageValues.leftFrontDoor?.values?.length,
    damageValues.leftFrontFender?.values?.length,
    damageValues.leftFrontLight?.values?.length,
    damageValues.leftMirror?.values?.length,
    damageValues.leftRearDoor?.values?.length,
    damageValues.leftRearFender?.values?.length,
    damageValues.rightFrontDoor?.values?.length,
    damageValues.rightFrontFender?.values?.length,
    damageValues.rightFrontLight?.values?.length,
    damageValues.rightMirror?.values?.length,
    damageValues.rightRearDoor?.values?.length,
    damageValues.rightRearFender?.values?.length,
    damageValues.roof?.values?.length,
    damageValues.other?.values?.length,
  ]);

  const countDamages: (object: object) => number = pipe(values, filter(gt(__, 0)), length);

  if (!exterior) {
    return null;
  }

  return (
    <SectionPrint
      icon="exterior"
      heading={i18n.t('exteriorHeader')}
      header={
        assignee?.name && isNotNilOrEmpty(exterior?.comment) ? (
          <CommentMechanic name={assignee.name} comment={exterior?.comment} />
        ) : null
      }
      flag={
        isStatusOkay
          ? {severity: 'good', text: i18n.t('sectionState.good')}
          : {
              severity: 'damage',
              text: getRecordsTranslate(countDamages(damagesData)),
            }
      }
    >
      <VStack spacing={10} width="100%">
        <Hide when={isTrue(isStatusOkay)}>
          <HStack spacing={6}>
            <Box position="relative" flex={1} ref={damagesRef}>
              <Image
                borderRadius="small"
                src="../../assets/images/exterior-front.jpg"
                width="100%"
                height="auto"
              />
              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                <ExteriorFrontOverlay />
              </Box>
            </Box>
            <Box position="relative" flex={1}>
              <Image
                borderRadius="small"
                src="../../assets/images/exterior-back.jpg"
                width="100%"
                height="auto"
              />
              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                <ExteriorBackOverlay />
              </Box>
            </Box>
          </HStack>
          <Show when={isNotNilOrEmpty(carouselData)}>
            <DamageCarouselPrint data={carouselData} />
          </Show>
        </Hide>

        <Show when={isTrue(isStatusOkay)}>
          <BannerSuccess />
          <Separator />
        </Show>
        <VStack spacing={10}>
          <Heading size={2}>{i18n.t('paintThickness')}</Heading>
          <Text color="tertiary">{i18n.t('paintThicknessDescription')}</Text>
        </VStack>
        <div
          css={css`
            display: ${getValueByDevice(device, 'none', 'block', 'block', 'block')};
          `}
        >
          <Box flex={1} ref={paintRef}>
            <PaintJob />
          </Box>
        </div>
      </VStack>
    </SectionPrint>
  );
}

const showDamagesOverlay = (points: Record<string, number>) => {
  keys(points).forEach((key) => {
    const count = points[key];
    if (isPositive(count)) {
      const partFrontTextElement = document.getElementById(`exterior-front-${key}-text`);
      const partFrontPointElement = document.getElementById(`exterior-front-${key}-point`);
      const partFrontOverlayElement = document.getElementById(`exterior-front-${key}-overlay`);
      if (partFrontTextElement) {
        partFrontTextElement.textContent = count.toString();
      }
      if (partFrontOverlayElement) {
        partFrontOverlayElement.style.opacity = '0.3';
      }
      if (partFrontPointElement) {
        partFrontPointElement.style.opacity = '1';
        partFrontPointElement.style.transform = 'scale(1)';
      }

      const partTextElement = document.getElementById(`exterior-back-${key}-text`);
      const partPointElement = document.getElementById(`exterior-back-${key}-point`);
      const partOverlayElement = document.getElementById(`exterior-back-${key}-overlay`);
      if (partTextElement) {
        partTextElement.textContent = count.toString();
      }
      if (partOverlayElement) {
        partOverlayElement.style.opacity = '0.3';
      }
      if (partPointElement) {
        partPointElement.style.opacity = '1';
        partPointElement.style.transform = 'scale(1)';
      }
    }
  });
};

const showPaintOverlay = (points: Record<string, ValueWithUnit | Nullish>, theme: DefaultTheme) => {
  keys(points).forEach((key) => {
    const thickness = points[key];
    if (isPositive(toNumber(thickness?.value))) {
      const partTextElements = document.getElementsByClassName(`svg-paint-${key}-text`);
      const partPointElements = document.getElementsByClassName(`svg-paint-${key}-point`);
      const partPointBackgroundElements = document.getElementsByClassName(
        `svg-paint-${key}-point-bg`
      );
      const partPointFillElements = document.getElementsByClassName(`svg-paint-${key}-point-fill`);
      const partOverlayElements = document.getElementsByClassName(`svg-paint-${key}-overlay`);
      const partOverlayFillElements = document.getElementsByClassName(`svg-paint-${key}-overlay`);
      const hasWarning = gt(thickness?.value ?? 0, WARNING_TRESHOLD);

      Object.values(partTextElements).forEach((partTextElement) => {
        partTextElement.textContent =
          getValueWithUnit({
            auditValue: thickness,
            defaultUnitTranslate: i18n.t('metricMicroMetre'),
          }) ?? EMPTY_PLACEHOLDER;
        if (isHTMLElement(partTextElement)) {
          partTextElement.style.fill = hasWarning
            ? theme.colors.text.primary
            : theme.colors.text.inverted;
        }
      });
      Object.values(partOverlayElements).forEach((partOverlayElement) => {
        if (isHTMLElement(partOverlayElement)) {
          partOverlayElement.style.opacity = '1';
        }
      });
      Object.values(partOverlayFillElements).forEach((partOverlayFillElement) => {
        if (isHTMLElement(partOverlayFillElement)) {
          partOverlayFillElement.style.fill = hasWarning
            ? theme.colors.severity.warningLight
            : theme.colors.severity.successLight;
        }
      });
      Object.values(partOverlayFillElements).forEach((partOverlayFillElement) => {
        if (isHTMLElement(partOverlayFillElement)) {
          partOverlayFillElement.style.fill = hasWarning
            ? theme.colors.severity.warningLight
            : theme.colors.severity.successLight;
        }
      });
      Object.values(partPointFillElements).forEach((partPointFillElement) => {
        if (isHTMLElement(partPointFillElement)) {
          partPointFillElement.style.fill = hasWarning
            ? theme.colors.severity.warningBase
            : theme.colors.severity.successBase;
        }
      });
      Object.values(partPointElements).forEach((partPointElement) => {
        if (isHTMLElement(partPointElement)) {
          partPointElement.style.opacity = '1';
          partPointElement.style.transform = 'scale(1)';
        }
      });
      if (gte(toNumber(thickness?.value), 1000)) {
        Object.values(partPointBackgroundElements).forEach((partPointBackgroundElement) => {
          if (isHTMLElement(partPointBackgroundElement)) {
            partPointBackgroundElement.style.transform = 'scaleX(1.2)';
          }
        });
      }
    }
  });
};

const isHTMLElement = (element: Element): element is HTMLElement => 'style' in element;

const WARNING_TRESHOLD = 300;
