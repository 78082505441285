import {Heading, HStack, Icon, Text, ThemeIconKey, useDevice, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

interface SpecificationItemProps extends TestIdProps {
  value: string | number;
  category: string;
  icon: ThemeIconKey;
}

export function SpecificationItemNew(props: SpecificationItemProps) {
  const valueIsNotEmptyString = props.value !== '';
  const device = useDevice();
  const iconSize = match(device)
    .with('mobile', always(5 as const))
    .with('tablet', always(7 as const))
    .with('notebook', always(7 as const))
    .otherwise(always(8 as const));
  return (
    <HStack spacing={4} align="center">
      <Icon size={iconSize} value={props.icon} />
      <VStack spacing={props.category && valueIsNotEmptyString ? 1 : 0}>
        <Text data-testid={suffixTestId('category', props)} size="xSmall" color="secondary">
          {props.category}
        </Text>
        <Heading data-testid={suffixTestId('value', props)} size={4}>
          {String(props.value)}
        </Heading>
      </VStack>
    </HStack>
  );
}
