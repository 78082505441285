import {Heading, HStack, Text} from 'platform/foundation';

type TimerType = {
  days: string | number;
  hours: string | number;
  minutes: string | number;
  seconds: string | number;
};

export const Timer = (props: TimerType) => (
  <HStack align="center" justify="space-between" width="100%" maxWidth={80}>
    <HStack spacing={1} align="center" width={6}>
      <Heading size={2}>{String(props.days)}</Heading>
      <Text size="small">d</Text>
    </HStack>
    <Heading size={3} color="tertiary">
      :
    </Heading>
    <HStack spacing={1} align="center" width={10}>
      <Heading size={2}>{String(props.hours)}</Heading>
      <Text size="small">h</Text>
    </HStack>
    <Heading size={3} color="tertiary">
      :
    </Heading>
    <HStack spacing={1} align="center" width={10}>
      <Heading size={2}>{String(props.minutes)}</Heading>
      <Text size="small">m</Text>
    </HStack>
    <Heading size={3} color="tertiary">
      :
    </Heading>
    <HStack spacing={1} align="center" width={10}>
      <Heading size={2}>{String(props.seconds)}</Heading>
      <Text size="small">s</Text>
    </HStack>
  </HStack>
);
