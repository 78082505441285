import {componentsTheme} from 'platform/components';

import {themeIcons} from '../assets/icons/themeIcons';
import {onlineOfferTheme} from './theme';

const accent = {
  darkest: '#000C20',
  darker: '#002C79',
  dark: '#003289',
  base: '#003899',
  light: '#90B9FF',
  lighter: '#D8E7FD',
  lightest: '#EBF6FE',
} as const;

const text = {
  primary: accent.darkest,
  secondary: '#42526E',
  tertiary: '#7A869A',
  inverted: '#FFFFFF',
  accent: accent.base,
  link: '#0047C1',
} as const;

const fill = {
  greyLight: '#F2F6F9',
  white: '#FFFFFF',
  overlayStrong: '#000C20F2',
  overlayMedium: '#000C20CC',
  cebia: '#5EDB3D',
} as const;

export const emilFreyTheme = {
  ...componentsTheme,
  radii: onlineOfferTheme.radii,
  fonts: onlineOfferTheme.fonts,
  fontWeights: onlineOfferTheme.fontWeights,
  fontSizes: onlineOfferTheme.fontSizes,
  lineHeights: onlineOfferTheme.lineHeights,
  letterSpacing: onlineOfferTheme.letterSpacing,
  devices: onlineOfferTheme.devices,
  zIndices: onlineOfferTheme.zIndices,
  icons: themeIcons,
  colors: {
    ...componentsTheme.colors,
    accent,
    text,
    fill,
    severity: onlineOfferTheme.colors.severity,
    border: onlineOfferTheme.colors.border,
  },
} as const;
