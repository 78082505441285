import {Grid, GridItem, Heading, Show, Space, Text, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {VehicleFeatureResponseBody} from '../../api/digitalCertificateApi';
import {CommentExpandable} from '../../components/CommentExpandable/CommentExpandable';
import {FeatureItem} from '../../components/FeatureItem/FeatureItem';
import {SectionNew} from '../../components/SectionNew/SectionNew';
import {
  COMFORT_AND_CONVENIENCE_KEY,
  FEATURE_CATEGORIES,
  FeatureCategory,
} from '../../consts/FeatureCategories';
import {FeaturesDataBundle} from '../../hooks/useGetDataForFeatures';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';

type FeaturesProps = Omit<FeaturesDataBundle, 'sectionConfig'>;
type CategoryBody = FeaturesDataBundle['categoriesWithFeatures'][0];

export function Features(props: FeaturesProps) {
  const {vehicleAudit} = useGetOnlineOfferData();

  const vehicleFeatures: Record<string, VehicleFeatureResponseBody> =
    vehicleAudit?.vehicle?.features?.reduce(
      (acc, feature) => ({
        ...acc,
        [feature.key]: {
          ...feature,
        },
      }),
      {}
    ) ?? {};

  const filteredCategories = props.categoriesWithFeatures.reduce((acc, feature) => {
    // auditStructure contains all features, but we are interested only in features that are present in vehicleAudit
    const intersectingFeatures = feature.features?.filter((feature) =>
      isNotNil(vehicleFeatures[feature.featureKey])
    );
    if (intersectingFeatures?.length > 0) {
      return {
        ...acc,
        [feature.uniqueKey]: {
          ...feature,
          features: intersectingFeatures
            // merge feature data (from auditStructure) with vehicle feature data from (vehicleAudit)
            .map((feature) => ({
              ...vehicleFeatures[feature.featureKey],
              ...feature,
            }))
            .sort((a, b) => {
              // sort alphabetically by default
              if (a.isHighlighted === b.isHighlighted && a.isPremium === b.isPremium) {
                return a.title.localeCompare(b.title);
              }

              // sort highlighted and premium features first
              if (a.isHighlighted || a.isPremium) {
                return -1;
              }
              if (b.isHighlighted || b.isPremium) {
                return 1;
              }
              return 0;
            }),
        },
      };
    }
    return acc;
  }, {}) as Record<
    FeatureCategory,
    Omit<CategoryBody, 'features'> & {
      features: (CategoryBody['features'][0] & VehicleFeatureResponseBody)[];
    }
  >;

  const renderCategoryItem = (key: FeatureCategory) =>
    filteredCategories[key]?.features?.length ? (
      <VStack spacing={6}>
        <Heading size={3}>{filteredCategories[key]?.title}</Heading>
        <VStack spacing={2}>
          {filteredCategories[key].features.map((feature, index) => (
            <FeatureItem
              key={feature.featureKey}
              title={feature.title}
              data-testid={`featureItem-[${index}]`}
              isHighlighted={feature.isPremium || feature.isHighlighted}
            />
          ))}
        </VStack>
      </VStack>
    ) : null;

  return (
    <SectionNew id="FEATURES" heading={i18n.t('featuresHeader')} isWithBreakLine>
      <Grid columns={[1, 2]} horizontalSpacing={8}>
        <GridItem span={1}>{renderCategoryItem(COMFORT_AND_CONVENIENCE_KEY)}</GridItem>
        <GridItem span={1}>
          <VStack spacing={9}>{FEATURE_CATEGORIES.slice(1).map(renderCategoryItem)}</VStack>
        </GridItem>
      </Grid>
      <Space vertical={7} />
      <Show when={isNotNil(vehicleAudit?.vehicle?.featuresByManufacturer)}>
        <Text size="large" alternative>
          {i18n.t('featuresInfo')}
        </Text>
        <Space vertical={2} />
        <CommentExpandable
          hideCommentFlag
          comment={vehicleAudit?.vehicle?.featuresByManufacturer}
        />
      </Show>
    </SectionNew>
  );
}
