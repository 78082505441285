import {Box, Clickable, getValueByDevice, useDevice} from 'platform/foundation';
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';

import {SlideImage} from '../../types/SlideImage';
import {AdaptiveImageDcNew} from '../AdaptiveImageDcNew/AdaptiveImageDcNew';
import {CarouselButton} from '../CarouselButton/CarouselButton';

interface CarouselSwiperProps {
  images: SlideImage[];
  onInspectImage: (slideShowItem: SlideImage) => void;
}

export const CarouselSwiper = (props: CarouselSwiperProps) => {
  const device = useDevice();
  const slidesPerView = getValueByDevice(device, 1, 2.5, 2.5, 3);
  return (
    <Box>
      <Swiper autoHeight={false} navigation={true} slidesPerView={slidesPerView} spaceBetween={4}>
        {props.images.map((image) => (
          <SwiperSlide key={image.id}>
            <Clickable onClick={() => props.onInspectImage(image)}>
              <AdaptiveImageDcNew fit="cover" url={image.url} />
            </Clickable>
          </SwiperSlide>
        ))}
        <CarouselButton variant="left" />
        <CarouselButton variant="right" />
      </Swiper>
    </Box>
  );
};
