import {environment} from '@online-offer/environment';

import {isNotNil} from 'ramda';

type GetWorkspaceFromUri = {
  workspace?: string;
  domain: string;
};

const DEFAULT_REVIEW_TENANT = 'carvago';
const REVIEW_APP = 'review';

const POSSIBLE_WORKSPACES = ['localhost', 'localhost:4201', 'dev', 'stage', 'review'];

/**
 * Get OMNETIC workspace from current hostname
 * The list of possible urls looks like this
 * 1. localhost:3000
 * 2. carvago.localhost:3000
 * 3. carvago.dev.teasdev.com
 * 4. develop.review.teasdev.com
 * 5. app.stage.teasdev.com
 */
export const getWorkspaceFromUri = (url?: string): GetWorkspaceFromUri => {
  const [wsp, ...rest] = (url || window.location.host).split('.');

  const isReviewApp = window.location.href.includes(REVIEW_APP);
  const isNoWorkspaceFound = POSSIBLE_WORKSPACES.includes(wsp);

  if (isNotNil(environment.workspace)) {
    return {
      workspace: environment.workspace,
      domain: window.location.host,
    };
  }

  if (isNoWorkspaceFound) {
    return {
      workspace: isReviewApp ? DEFAULT_REVIEW_TENANT : wsp,
      domain: window.location.host,
    };
  }

  const domain = rest.join('.');

  return {
    workspace: isReviewApp ? DEFAULT_REVIEW_TENANT : wsp,
    domain,
  };
};
