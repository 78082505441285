import {Hide, HStack, Icon, Integer, ThemeIconKey, ValueByDevice} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

type ButtonSize = 'default' | 'small';
type ButtonVariant = 'primary' | 'secondary' | 'inverted' | 'outline' | 'link';

export interface ButtonProps extends TestIdProps {
  title: string;
  icon?: ThemeIconKey;
  size?: ButtonSize;
  variant: ButtonVariant;
  isDisabled?: boolean;
  onClick?: VoidFunction;
  leftIcon?: ThemeIconKey;
  leftIconSize?: Integer | ValueByDevice<Integer>;
  rightIcon?: ThemeIconKey;
}

export function Button(props: ButtonProps) {
  return (
    <StyledButton
      role="button"
      $variant={props.variant}
      $size={props.size ?? 'default'}
      $isDisabled={!!props.isDisabled}
      data-testid={suffixTestId('buttonIcon', props)}
      onClick={props.onClick}
    >
      <HStack justify="center" spacing={2} align="center">
        <Hide when={isNil(props.leftIcon)}>
          <Icon value={props.leftIcon} size={props.leftIconSize ?? 5} />
        </Hide>
        {props.title}
        <Hide when={isNil(props.rightIcon)}>
          <Icon value={props.rightIcon} size={4} />
        </Hide>
      </HStack>
    </StyledButton>
  );
}

// eslint-disable-next-line eag/no-css-property
const StyledButton = styled.button<{
  $variant: ButtonVariant;
  $isDisabled: boolean;
  $size: ButtonSize;
}>`
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  padding: ${({theme, $size}) => `0 ${theme.getSize($size === 'small' ? 3 : 6)}`};
  border-radius: ${({theme}) => theme.radii.large};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 11)};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.5 : 1)};
  cursor: ${({$isDisabled}) => ($isDisabled ? 'auto' : 'pointer')};
  pointer-events: ${({$isDisabled}) => ($isDisabled ? 'none' : 'all')};
  font-family: ${({theme}) => theme.fonts.body};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.bold};
  letter-spacing: ${({theme}) => theme.letterSpacing.text.small};
  color: ${({$variant, theme}) =>
    match<ButtonVariant>($variant)
      .with('primary', always(theme.colors.text.white))
      .with('secondary', always(theme.colors.text.accent))
      .with('inverted', always(theme.colors.text.primary))
      .with('outline', always(theme.colors.text.primary))
      .with('link', always(theme.colors.text.link))
      .exhaustive()};
  background-color: ${({$variant, theme}) =>
    match<ButtonVariant>($variant)
      .with('primary', always(theme.colors.accent.base))
      .with('secondary', always(theme.colors.accent.lightest))
      .with('inverted', always(theme.colors.fill.white))
      .with('outline', always(theme.colors.general.transparent))
      .with('link', always(theme.colors.general.transparent))
      .exhaustive()};
  border: ${({$variant, theme}) =>
    $variant === 'outline' ? `1px solid ${theme.colors.border.emphasisDefault}` : 'none'};

  &:hover {
    color: ${({$variant, theme}) =>
      match<ButtonVariant>($variant)
        .with('primary', always(theme.colors.text.white))
        .with('secondary', always(theme.colors.text.accent))
        .with('inverted', always(theme.colors.text.accent))
        .with('outline', always(theme.colors.text.primary))
        .with('link', always(theme.colors.text.link))
        .exhaustive()};
    background-color: ${({$variant, theme}) =>
      match<ButtonVariant>($variant)
        .with('primary', always(theme.colors.accent.dark))
        .with('secondary', always(theme.colors.accent.lighter))
        .with('inverted', always(theme.colors.fill.white))
        .with('outline', always(theme.colors.border.emphasisDefault))
        .with('link', always(theme.colors.general.transparent))
        .exhaustive()};
  }
`;
