import {Show, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useLocale} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {CarouselPrint} from '../../components/Carousel/Carousel.print';
import {
  DocumentationAttribute,
  DocumentationItem,
} from '../../components/DocumentationItem/DocumentationItem';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {Separator} from '../../components/Separator/Separator';
import {getSlideshowByUniqueKey} from '../../components/Slideshow/utils/getSlideshowByUniqueKey';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {getServiceHistory} from '../../utils/getServiceHistory';
import {getValueWithUnit} from '../../utils/getValueWithUnit';

export function ServiceHistoryPrint() {
  const {vehicleAudit} = useGetOnlineOfferData();

  const locale = useLocale();
  const formatDateTime = useDateTimeFormatter();

  const serviceHistory = getServiceHistory({vehicleAudit, language: locale.language});
  const serviceHistorySlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'SERVICE_HISTORY',
  });

  const getDate = (value: string | number | Nullish) =>
    isNotNil(value) ? formatDateTime('dateShort', parseDate(value)) : null;

  if (!serviceHistory) {
    return null;
  }

  const serviceHistoryAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory?.inspections?.regularServiceIntervalPerformed), {
      label: serviceHistory?.inspections?.regularServiceIntervalPerformed?.label ?? '',
      value:
        serviceHistory?.inspections?.regularServiceIntervalPerformed?.value ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.dateOfLastService), {
      label: serviceHistory?.inspections?.dateOfLastService?.label ?? '',
      value: getDate(serviceHistory?.inspections?.dateOfLastService?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.dateOfNextService), {
      label: serviceHistory?.inspections?.dateOfNextService?.label ?? '',
      value: getDate(serviceHistory?.inspections?.dateOfNextService?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.mileageOfLastService), {
      label: serviceHistory?.inspections?.mileageOfLastService?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.inspections?.mileageOfLastService?.value,
        }) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.inspections?.mileageOfNextService), {
      label: serviceHistory?.inspections?.mileageOfNextService?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.inspections?.mileageOfNextService?.value,
        }) ?? EMPTY_PLACEHOLDER,
    });

  const lastOilChangeAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory?.lastOilChange?.dateOfLastOilChange), {
      label: serviceHistory?.lastOilChange?.dateOfLastOilChange?.label ?? '',
      value:
        getDate(serviceHistory?.lastOilChange?.dateOfLastOilChange?.value) ?? EMPTY_PLACEHOLDER,
    })
    .when(isAttributeVisible(serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange), {
      label: serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange?.label ?? '',
      value:
        getValueWithUnit({
          localeConfig: locale.localeConfig,
          auditValue: serviceHistory?.lastOilChange?.amountOfKmDrivenFromOilChange?.value,
        }) ?? EMPTY_PLACEHOLDER,
    });

  const lastBrakeFluidChangeAttributes = buildArray<DocumentationAttribute>()
    .when(isAttributeVisible(serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange), {
      label: serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange?.label ?? '',
      value:
        getDate(serviceHistory?.lastBrakeFluidChange?.dateOfLastBrakeFluidChange?.value) ??
        EMPTY_PLACEHOLDER,
    })
    .when(
      isAttributeVisible(
        serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange
      ),
      {
        label:
          serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange?.label ?? '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastBrakeFluidChange?.amountOfKmDrivenFromBrakeFluidChange?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );

  const lastOilChangeInGearboxAttributes = buildArray<DocumentationAttribute>()
    .when(
      isAttributeVisible(serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox),
      {
        label: serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox?.label ?? '',
        value:
          getDate(serviceHistory?.lastOilChangeInGearbox?.dateOfLastOilChangeInGearbox?.value) ??
          EMPTY_PLACEHOLDER,
      }
    )
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox
      ),
      {
        label:
          serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox?.label ??
          '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastOilChangeInGearbox?.amountOfKmDrivenFromOilChangeInGearbox?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );

  const lastOilChangeInDifferentialAttributes = buildArray<DocumentationAttribute>()
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential
      ),
      {
        label:
          serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential?.label ??
          '',
        value:
          getDate(
            serviceHistory?.lastOilChangeInDifferential?.dateOfLastOilChangeInDifferential?.value
          ) ?? EMPTY_PLACEHOLDER,
      }
    )
    .when(
      isAttributeVisible(
        serviceHistory?.lastOilChangeInDifferential?.amountOfKmDrivenFromOilChangeInDifferential
      ),
      {
        label:
          serviceHistory?.lastOilChangeInDifferential?.amountOfKmDrivenFromOilChangeInDifferential
            ?.label ?? '',
        value:
          getValueWithUnit({
            localeConfig: locale.localeConfig,
            auditValue:
              serviceHistory?.lastOilChangeInDifferential
                ?.amountOfKmDrivenFromOilChangeInDifferential?.value,
          }) ?? EMPTY_PLACEHOLDER,
      }
    );

  return (
    <SectionPrint icon="repaired" heading={i18n.t('serviceHistory')}>
      <VStack spacing={6}>
        <Show when={isNotNilOrEmpty(serviceHistoryAttributes)}>
          <DocumentationItem
            isPrintView
            attributes={serviceHistoryAttributes}
            heading={i18n.t('serviceInspections')}
            comment={serviceHistory?.inspections?.comment}
          />
          <Separator />
        </Show>
        <Show when={isNotNilOrEmpty(lastOilChangeAttributes)}>
          <DocumentationItem
            isPrintView
            heading={i18n.t('lastOilChange')}
            attributes={lastOilChangeAttributes}
            comment={serviceHistory?.lastOilChange?.comment}
          />
          <Separator />
        </Show>
        <Show when={isNotNilOrEmpty(lastBrakeFluidChangeAttributes)}>
          <DocumentationItem
            isPrintView
            heading={i18n.t('lastBrakeFluidChange')}
            attributes={lastBrakeFluidChangeAttributes}
            comment={serviceHistory?.lastBrakeFluidChange?.comment}
          />
          <Separator />
        </Show>
        <Show when={isNotNilOrEmpty(lastOilChangeInGearboxAttributes)}>
          <DocumentationItem
            isPrintView
            heading={i18n.t('lastOilChangeInGearbox')}
            attributes={lastOilChangeInGearboxAttributes}
            comment={serviceHistory?.lastOilChangeInGearbox?.comment}
          />
          <Separator />
        </Show>
        <Show when={isNotNilOrEmpty(lastOilChangeInDifferentialAttributes)}>
          <DocumentationItem
            isPrintView
            heading={i18n.t('lastOilChangeInDifferential')}
            attributes={lastOilChangeInDifferentialAttributes}
            comment={serviceHistory?.lastOilChangeInDifferential?.comment}
          />
        </Show>
        <Show when={isNotNil(serviceHistorySlideshow)}>
          <Separator />
          <CarouselPrint isFilterOfDamageDisabled data={serviceHistorySlideshow} />
        </Show>
      </VStack>
    </SectionPrint>
  );
}

const isAttributeVisible = (attribute: {label: string | Nullish}) =>
  isNotNilOrEmpty(attribute?.label);
