import {Nullish} from 'shared';

import {SectionNew} from '../../components/SectionNew/SectionNew';
import {getRatingCardParams} from '../Hero/utils/getRatingCardParams';
import {CertificateBadge} from './CertificateBadge';

type CertificateProps = {
  data: {
    digitalCertificateVehicleId: string | Nullish;
    ratings: ReturnType<typeof getRatingCardParams> | Nullish;
  };
};

export const Certificate = ({data}: CertificateProps) => (
  <SectionNew id="CERTIFICATE" heading="Stav vozu" isWithBreakLine>
    <CertificateBadge
      title="Certifikát stavu vozu"
      digitalCertificateVehicleId={data.digitalCertificateVehicleId}
      ratings={data.ratings}
    />
  </SectionNew>
);
