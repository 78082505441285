import {environment} from '@online-offer/environment';
import {
  Box,
  CSSDimension,
  Device,
  Grid,
  Heading,
  Image,
  Integer,
  Show,
  Space,
  Stack,
  useDevice,
} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {always} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish, TestIdProps} from 'shared';

import {Button} from '../../components/Button/Button';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';
import {getRatingCardParams} from '../Hero/utils/getRatingCardParams';
import {CertificateCardRating} from './CertificateCardRating';

export interface CertificateBadgeProps extends TestIdProps {
  title: string | Nullish;
  digitalCertificateVehicleId: string | Nullish;
  ratings: ReturnType<typeof getRatingCardParams> | Nullish;
}

export function CertificateBadge(props: CertificateBadgeProps) {
  const {isOnlineOfferLogoShown} = getWorkspaceCustomization();
  const device = useDevice();

  const top = match<Device, CSSDimension>(device)
    .with('mobile', always('10%'))
    .with('tablet', always('-115%'))
    .with('notebook', always('-90%'))
    .otherwise(always('-62%'));

  const right = match<Device, CSSDimension>(device)
    .with('mobile', always('-45%'))
    .with('tablet', always('-37%'))
    .with('notebook', always('-47%'))
    .otherwise(always('-29%'));

  const maxImageWidth = match<Device, Integer>(device)
    .with('mobile', always(109))
    .with('tablet', always(140))
    .with('notebook', always(140))
    .otherwise(always(235));

  const shouldShowRating =
    props.ratings &&
    (isNotNilOrEmpty(props.ratings.exterior.rating) ||
      isNotNilOrEmpty(props.ratings.interior.rating) ||
      isNotNilOrEmpty(props.ratings.mechanic.rating));

  return (
    <Box
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
      position="relative"
      borderRadius="small"
      paddingVertical={[6, 6, 6, 8]}
      paddingHorizontal={[6, 6, 6, 8]}
      data-testid="certificateBadge"
    >
      <GradientBackground />
      <Box position="absolute" overflow="hidden" top={top} right={right}>
        <Image
          data-testid="watermark"
          isLazy
          maxWidth={maxImageWidth}
          fit="cover"
          width="100%"
          height="100%"
          src="../../assets/images/watermark2.svg"
        />
      </Box>
      <Box position="relative">
        <Stack
          spacing={[6, 3, 10, 10]}
          direction={['column', 'row', 'row', 'row']}
          align={['center', 'center', 'center', 'center']}
          justify="space-between"
        >
          <Stack
            direction="row"
            spacing={10}
            width="100%"
            justify={['space-between', 'flex-start']}
            align="center"
          >
            <Show when={isOnlineOfferLogoShown}>
              <Image
                data-testid="logo"
                isLazy
                src="../../assets/images/logo.svg"
                alt="Online Offer"
                height={[5, 6, 8, 6]}
              />
            </Show>
            <Heading data-testid="certificateBadgeMakeModel" size={3}>
              {props.title}
            </Heading>
          </Stack>
          <Stack direction={['column', 'row']} width={['100%', 'auto']}>
            <Button
              variant="primary"
              title="Zobrazit výsledky"
              rightIcon="action/open_in_new"
              onClick={() => {
                if (props.digitalCertificateVehicleId) {
                  const host = window.location.host.split('.');
                  const dcUrl = environment.DIGITAL_CERTIFICATE_URL.split('https://');
                  window.open(
                    `https://${host.length >= 2 ? host[0] + '.' : ''}${dcUrl[1]}/${props.digitalCertificateVehicleId}`
                  );
                }
              }}
            />
          </Stack>
        </Stack>
        <Show when={shouldShowRating}>
          <Space vertical={6} />
          <Grid columns={[1, 3]} spacing={8}>
            {props.ratings?.exterior && (
              <CertificateCardRating
                rating={props.ratings.exterior.rating}
                title={props.ratings.exterior.title}
                icon={props.ratings.exterior.icon}
              />
            )}
            {props.ratings?.interior && (
              <CertificateCardRating
                rating={props.ratings.interior.rating}
                title={props.ratings.interior.title}
                icon={props.ratings.interior.icon}
              />
            )}
            {props.ratings?.mechanic && (
              <CertificateCardRating
                rating={props.ratings.mechanic.rating}
                title={props.ratings.mechanic.title}
                icon={props.ratings.mechanic.icon}
              />
            )}
          </Grid>
        </Show>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line eag/no-css-property
const GradientBackground = styled.div`
  position: absolute;
  pointer-events: none;
  inset: 0;
  background: linear-gradient(90deg, #fcf2fc 0%, #f9f8f2 52.08%, #f5f0ff 100%);
`;
