import {useEffect, useState} from 'react';

import {parseDate} from 'shared';

import {ReservationCard} from '../ReservationCard/ReservationCard';
import {TimerCard, TimerState, TimerStateType} from '../TimerCard/TimerCard';

type SidebarType = {
  deadline: Date;
};

const getTimerState = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}): TimerState => {
  if (days === 0 && hours < 2) {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return 'expired';
    } else {
      return 'soon_expired';
    }
  }
  return 'reserved';
};

const getTime = (deadline: Date) => {
  const time = parseDate(deadline).getTime() - new Date().getTime();
  return {
    days: Math.floor(time / (1000 * 60 * 60 * 24)),
    hours: Math.floor((time / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((time / 1000 / 60) % 60),
    seconds: Math.floor((time / 1000) % 60),
  };
};

export function Sidebar(props: SidebarType) {
  const initialTime = getTime(props.deadline);
  const [timer, setTimer] = useState<TimerStateType>({
    ...initialTime,
    state: getTimerState(initialTime),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getTime(props.deadline);
      const timerState = getTimerState(time);
      setTimer({...time, state: timerState});
      if (time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [props.deadline]);

  return (
    <>
      <TimerCard timer={timer} />
      <ReservationCard isExpired={timer.state === 'expired'} />
    </>
  );
}
