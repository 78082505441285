import {APPLICATION_NAME} from '@online-offer/config';
import {
  Box,
  Center,
  getValueByDevice,
  Heading,
  Hide,
  HStack,
  Show,
  Space,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useLazyGetPdfByAuditIdQuery} from '../../api/digitalCertificateApi';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {getCertificateHeader} from '../../utils/getCertificateHeader';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';
import {getWorkspaceFromUri} from '../../utils/getWorkspaceFromUri';
import {normalizeString} from '../../utils/normalizeString';
import {Button} from '../Button/Button';
import {ButtonIcon} from '../ButtonIcon/ButtonIcon';
import {MobileNavigation} from './components/MobileNavigation';

const {workspace} = getWorkspaceFromUri();

export interface NavigationProps extends TestIdProps {
  sections: string[];
}

export function NavigationNew(props: NavigationProps) {
  const params = useParams<{auditId: string}>();
  const locale = useLocale();

  const {logo: applicationLogo} = getWorkspaceCustomization();

  const {vehicleAudit, vehicleCatalogue} = useGetOnlineOfferData();
  const [getPdfByAuditId, {isFetching, isSuccess, isError}] = useLazyGetPdfByAuditIdQuery();

  const [isMobileNavigationOpen, openMobileNavigation, closeMobileNavigation] = useBoolean();
  const device = useDevice();

  const certificateHeader = getCertificateHeader({
    vehicle: vehicleAudit?.vehicle,
    vehicleCatalogue,
  });

  const downloadPdf = () => {
    if (!params.auditId || !workspace) {
      return;
    }

    getPdfByAuditId({
      auditId: params.auditId,
      workspace,
      locale: locale.language,
      filename: certificateHeader?.makeModel
        ? normalizeString(certificateHeader.makeModel)
        : 'online-offer',
    })
      .unwrap()
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = response.uri;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch(() => {});
  };

  const shareReport = () => {
    navigator
      .share({
        title: `${certificateHeader?.makeModel} • ${APPLICATION_NAME}`,
        url: window.location.href,
      })
      .then(() => {
        if (isMobileNavigationOpen) {
          closeMobileNavigation();
        }
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
  };

  return (
    <>
      <Box
        position={getValueByDevice(device, 'sticky', 'static', 'static', 'static')}
        top={0}
        backgroundColor="general.white"
        zIndex="MENU_BAR"
        data-testid={suffixTestId('navigation', props)}
      >
        <Center justify="center" width="100%">
          <Box
            flexGrow={1}
            maxWidth={432}
            paddingTop={[2, 4, 4, 6]}
            paddingBottom={[0, 4, 4, 6]}
            paddingHorizontal={[3, 8, 8, 20]}
          >
            <HStack align="center" justify="space-between">
              {applicationLogo}
              <Hide onMobile>
                <Box paddingLeft={8}>
                  <VStack>
                    <Text size="small" color="secondary">
                      Obchodní nabídka 2038409 pro:
                    </Text>
                    <Heading size={4}>Marek Dvořák</Heading>
                  </VStack>
                </Box>
              </Hide>
              <Space horizontal={4} fillAvailable />
              <HStack spacing={2}>
                <Show when={navigator.share}>
                  <Show onMobile>
                    <ButtonIcon
                      data-testid="shareButton"
                      variant="outline"
                      icon="share"
                      onClick={shareReport}
                      size="small"
                    />
                  </Show>
                  <Hide onMobile>
                    <Button
                      data-testid="shareButton"
                      variant="outline"
                      title={i18n.t('share')}
                      leftIcon="share"
                      onClick={shareReport}
                    />
                  </Hide>
                </Show>
              </HStack>
            </HStack>
            <Show onMobile>
              <Box paddingTop={3}>
                <Show onMobile>
                  <VStack>
                    <Text size="small" color="secondary">
                      Obchodní nabídka 2038409 pro:
                    </Text>
                    <Heading size={4}>Marek Dvořák</Heading>
                  </VStack>
                </Show>
              </Box>
            </Show>
          </Box>
        </Center>
      </Box>
      <MobileNavigation
        onShareClick={shareReport}
        onDownloadClick={downloadPdf}
        sections={props.sections}
        isVisible={isMobileNavigationOpen}
        onClose={closeMobileNavigation}
        isPdfDownloading={isFetching}
      />
    </>
  );
}
